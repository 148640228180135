<template>
  <div>
    <div class="testHistory" v-if="test">
      <div class="testHistoryHeader ">
        <div class="d-flex " :class="$vuetify.breakpoint.mdAndDown && 'flex-wrap'">
          <div>
            <img :src="test.img" width="80px" alt="">
          </div>
          <div class="d-flex flex-column justify-center pr-3">
            <div class="titleText">
              {{ test.title }}
            </div>
            <div class="descText mt-2">
              {{ test.desc }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="d-flex flex-column justify-center text-right " :style="$vuetify.breakpoint.smAndDown && 'width: 100%' " :class="$vuetify.breakpoint.mdAndDown && 'mt-5'">
            <v-btn color="#3CBE8B" class="white--text" small elevation="0"
                   :to="{name:'testStepper',params: {testID:test.id}}">
              انجام مجدد تست
            </v-btn>
          </div>
        </div>
      </div>
      <template v-if="history && history.length != 0">
        <v-row>
          <v-col cols="12" lg="6" :order="$vuetify.breakpoint.mdAndUp ? 1 : 2">
            <div class="testHistoryTimeline mt-5">
              <div class="timelines">
                <div class="timeline_item" v-for="(item , i) in history" :key="i">
                  <div class="timeline_date d-flex justify-center flex-column" v-if="$vuetify.breakpoint.mdAndUp">
                    <div class="dateHistory">{{ historyDateFormat(item.date) }}</div>
                  </div>
                  <div class="timeline_marker">
                    <div class="line"></div>
                    <div class="pointer"></div>
                  </div>
                  <div class="timeline_item_content py-5">
                    <div class="d-flex">
                      <div>
                        <div class="zonesHistory">
                          <template v-if="!isComplexInterpretation(test.id)">
                            <div class="zoneName font-weight-bold">
                              نمره تست : {{ item.result[0].score.sum }}
                            </div>
                            <div class="underZone_list">
                              <div class="" v-for="(zone , i) in item.result" :key="i">
                                <template
                                    v-if="zone.name != 'Main Zone' && zone.name != 'زون اصلی' && typeof zone.score == 'object'">
                                  {{ zone.name }} : {{ zone.score.sum }}
                                </template>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="" v-for="(zone , i) in item.result" :key="i">
                              <template
                                  v-if="zone.name != 'Main Zone' && zone.name != 'زون اصلی' && typeof zone.score == 'object'">
                                <div class="zoneName">
                                  {{ zone.name }} : {{ zone.score.sum }}
                                </div>
                              </template>
                            </div>
                          </template>
                          <div class="dateHistory mt-2" v-if="$vuetify.breakpoint.mdAndDown">
                            {{ historyDateFormat(item.date) }}
                          </div>
                        </div>
                      </div>
                      <v-spacer></v-spacer>
                      <div class=" d-flex flex-column justify-center"
                           :class="$vuetify.breakpoint.mdAndDown ? 'pl-2' : 'pl-15'">
                        <v-btn color="#3CBE8B" class="white--text" x-small elevation="0"
                               :to="{name:'interpretationTest',params:{testID:test.id,userTestId:item.uuid,lang:'fa'}}">
                          نمایش‌ کامل تفسیر
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1">
            <div class="chartArea   fill-height" :class="$vuetify.breakpoint.mdAndUp && 'pl-5'">
              <div class="chartBlock" style="position: sticky;top: 60px">
                <div class="mt-5 mb-5 text-center">
                  نمرات شما در
                  {{ test.title }}
                </div>
                <vue-apex-charts type="line" v-if="series.length != 0"
                                 :class="$vuetify.breakpoint.smAndDown && 'mr-3'"
                                 :height="$vuetify.breakpoint.mdAndUp ? 500   : 400"
                                 :options="chartOptions" :series="series"></vue-apex-charts>
              </div>
            </div>

          </v-col>
        </v-row>
      </template>
      <template v-else>
        <div class="fill-height text-center mt-10">
          <img src="../../assets/EmptyState.jpg" width="200px" alt="">
          <p>
            تاریخچه‌ای از این تست وجود ندارد.
          </p>
          <v-btn color="#3CBE8B" class="white--text" small elevation="0"
                 :to="{name:'testStepper',params: {testID:test.id}}">
            انجام تست
          </v-btn>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import momentJalali from "moment-jalaali";
import VueApexCharts from 'vue-apexcharts'

/*eslint-disable*/
export default {
  components: {VueApexCharts},
  computed: {
    ...mapGetters({
      'test': 'getTest',
    }),
    chartOptions() {
      return {
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: this.$vuetify.breakpoint.mdAndUp
        },
        stroke: {
          curve: 'smooth',
          width: '2'
        },

        title: {
          text: '',
          align: 'right'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'white  '], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: this.chartLabels,
        }
      }
    },
    series() {
      return this.chartSeries
    }
  },
  data() {
    return {
      history: null,
      // series: [{
      //   name: "Desktops",
      //   data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      // }, {
      //   name: "Mobiles",
      //   data: [20, 25, 14, 25, 10, 25, 14, 10, 20]
      // }],
      chartLabels: [],
      chartSeries: [],
      complexInterpretation: [3, 17, 7]
    }
  },
  methods: {
    isComplexInterpretation(testId) {
      return this.complexInterpretation.findIndex(item => item == testId) != -1
    },
    getTestHistory() {
      this.$emitEvent('setLoading', true)
      this.requesting('test', 'getTestHistory', {testID: this.test.id}).then((resp) => {
        this.history = resp.data[0]
        console.log(this.history)
      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    getStatisticsTest() {
      this.requesting('test', 'getStatisticsTest', {testID: this.test.id}).then((resp) => {
        // console.log(,'121212')
        console.log(resp.data)
        if (resp.data.length != 0 && resp.data.zoneScoreStatistics.length != 0) {
          this.prepareChartLabel(resp.data.zoneScoreStatistics)
          this.prepareChartSeries(resp.data.zoneScoreStatistics)
        }
      })
    },
    prepareChartLabel(zones) {
      zones[0].statistics.forEach((item) => {
        let date = momentJalali(item.date).format('jYYYY/jMM/jDD')
        // if (this.chartLabels.findIndex(item => item == date) == -1) {
        this.chartLabels.push(date)
        // }
      })
    },
    prepareChartSeries(zones) {

      if (this.isComplexInterpretation(this.test.id)) {
        zones.forEach((item) => {
          if (item.name != 'Main Zone') {
            this.chartSeries.push({
              name: item.name,
              data: []
            })
            item.statistics.forEach((stac) => {
              let index = this.chartSeries.findIndex(i => i.name == item.name)
              if (index != -1) {
                this.chartSeries[index].data.push(stac.score.sum)
              }
            })
          }
        })
      } else {

        this.chartSeries.push({
          name: zones[0].name,
          data: []
        })
        zones[0].statistics.forEach((stac) => {
          let index = this.chartSeries.findIndex(i => i.name == zones[0].name)
          if (index != -1) {
            this.chartSeries[index].data.push(stac.score.sum)
          }
        })

      }


    },
    historyDateFormat(date) {
      let month = momentJalali(date).format('jMMMM')
      return momentJalali(date).format('jDD') + this.$t(`months.${month}`) + "  " + momentJalali(date).format('jYYYY')
    }
  },
  mounted() {
    if (this.test) {
      this.getStatisticsTest()
      this.getTestHistory()
    } else {
      this.$router.push({name: "tests", params: {lang: 'fa'}})
    }
  }
}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 600px) {
  .timeline_marker {
    width: 50px !important;
  }

  .testHistoryHeader {
    margin-left: 0px !important;
  }
  .descText{
    width:  auto !important;
  }
}

.underZone_list {
  font-size: 11px;
  line-height: 20pt;
}

.timeline_date {
  width: 90px;
  text-align: center;
}

.dateHistory {
  font-size: 11px;
  color: gray;
}

.zoneName {
  font-size: 15px;
  line-height: 23pt;
}

.timeline_item_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  border-bottom: 1px solid #ededed;
}

.descText {
  font-size: 13px;
  width: 80%;
  line-height: 20pt;
}

.testHistoryHeader {
  background: white;
  padding: 5px;
  margin-left: 20px;
  border-radius: 10px;
}

.titleText {
  font-weight: bolder;
}

.timeline_item {
  display: flex;
}

.timeline_marker {
  width: 80px;
  position: relative;
}

.line {
  width: 2px;
  height: 100%;
  background: #dddddd;
  margin: 0px auto;
}

.pointer {
  width: 32px;
  height: 32px;
  background: #3CBE8B;
  border: 5px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>